/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { API } from "@roambee/client-utility";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
// @ts-ignore
import { Button, Table, Pagination, Modal, InfoDetail, Title, SelectCheckboxesSearch, Small, Tag, IconButton } from "@roambee/client-styleguide";
import { Skeleton, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import NodesMap from "./NodesMap";
import * as turf from "@turf/turf";
import { MultiPolygon, Polygon, Feature } from "geojson";
import { ENDPOINTS, COLORS } from "../NodeIntelConfig";
import { Map, ShapeUnite, ShapeIntersect, Reset } from "@carbon/icons-react";

type Node = {
	name: string;
	visibility: string;
	issues?: string;
	address: string;
	overlaps: string;
	intersects: string;
};

function NodesTable() {
	const columns = [
		{
			field: "name",
			headerName: "Name",
			isSortable: false,
			isSearchable: true
		},
		{
			field: "address",
			headerName: "Address",
			isSortable: false,
			isSearchable: false
		},
		{
			field: "visibilityTag",
			headerName: "Visibility",
			width: 8,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "overlaps",
			headerName: "Overlaps",
			width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "intersects",
			headerName: "Intersections",
			width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "action",
			headerName: "",
			width: 3,
			isSortable: false,
			isSearchable: false
		}
	];
	const [data, setData] = useState<[]>([]); // processed data
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0); // total groups
	const [totalNodes, setTotalNodes] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [currentData, setCurrentData] = useState<Node[]>([]); // data for current page
	const [originalData, setOriginalData] = useState<Record<string, string | number>[][]>([]); // original data received from API
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [shapes, setShapes] = useState<Feature<Polygon | MultiPolygon>[]>([]);
	const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
	const [selectedShapes, setSelectedShapes] = useState<Feature<Polygon | MultiPolygon>[]>([]);
	const [geo, setGeo] = useState<object>({}); // geos of all shapes
	const [intersectedShapes, setIntersectedShapes] = useState<Feature<Polygon | MultiPolygon>[]>([]);
	const [combinedShapes, setCombinedShapes] = useState<Feature<Polygon | MultiPolygon>[]>([]);
	const [normalView, setNormalView] = useState(true); // view that shows all geofences individually
	const [unionView, setUnionView] = useState(false);
	const [title, setTitle] = useState("Map");
	const geoRef = useRef(geo);
	const inputRef = useRef(null);

	const fetchData = async () => {
		try {
			const nodesData = await API("GET", ENDPOINTS.NODE_INFO);
			setOriginalData(nodesData);
			resetData(nodesData);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};

	/**
	 * Fetches geographical shapes data from the API to render the map.
	 * Makes a GET request to the `/account/node-geo/`
	 *
	 * @async
	 * @function fetchShapes
	 * @returns {Promise<void>}
	 */
	const fetchShapes = async () => {
		try {
			const response = await API("GET", ENDPOINTS.NODE_GEO);
			setGeo(response);
		} catch (error) {
			console.error("Error fetching shapes:", error);
		}
	};

	/**
	 * Processes the node data received from the API.
	 *
	 * This function transforms the raw node data into a format suitable for rendering
	 * in the NodesTable component. It constructs JSX elements for overlaps and intersections,
	 * and creates a button for each node name that allows users to view the node on a map.
	 *
	 * @param {Object} nodesData - The raw data received from the API containing node information.
	 * @param {Array} nodesData.data - An array of pages, each containing an array of nodes.
	 *
	 * @returns {void} It updates the component's state
	 * with the processed data and other relevant information.
	 * **/
	const resetData = (nodesData) => {
		const modifiedData = nodesData?.data?.map((pageData) => {
			return pageData?.data.map((node) => {
				const over = (
					<>
						{Object.values(node["overlaps"]?.zone).length > 0 && (
							<>
								<Small $bold={true}>Zones:</Small>
								{Object.values(node["overlaps"]?.zone).join(", ")}
								<br />
							</>
						)}
						{Object.values(node["overlaps"]?.location).length > 0 && (
							<>
								<Small $bold={true}>Locations:</Small>
								{Object.values(node["overlaps"]?.location).join(", ")}
							</>
						)}
					</>
				);

				const inter = (
					<>
						{Object.values(node["intersections"]?.zone).length > 0 && (
							<>
								<Small $bold={true}>Zones:</Small>
								{Object.values(node["intersections"]?.zone).join(", ")}
								<br />
							</>
						)}
						{Object.values(node["intersections"]?.location).length > 0 && (
							<>
								<Small $bold={true}>Locations:</Small>
								{Object.values(node["intersections"]?.location).join(", ")}
							</>
						)}
					</>
				);
				const action = <IconButton ariaLabel="Actions" icon={<Map />} size="small" variant="no-bg" onClick={() => openOnMap(node)} className="map-icon" />;
				const visibilityTag = <Tag label={node["visibility"]} backgroundColor={node["visibility"] === "zone" ? COLORS.TAG_BLUE : COLORS.TAG_YELLOW} color={node["visibility"] === "zone" ? COLORS.MAP_BLUE : COLORS.MAP_YELLOW} small={true}></Tag>;

				return { ...node, overlaps: over, intersects: inter, visibilityTag, action };
			});
		});
		setData(modifiedData);
		setTotalPages(nodesData?.totalPages);
		setIsSearching(false);
		setCurrentData(Object.values(modifiedData?.[currentPage - 1] || {}));
		setTotalNodes(nodesData?.totalNodes);
	};

	const updateData = () => {
		setCurrentData(Object.values(data[currentPage - 1] || {}));
		setLoading(false);
	};

	const handleSearch = (searchObj: Record<string, unknown>) => {
		const searchString = searchObj?.search?.[0]?.value || "";
		if (!searchString) {
			resetData(originalData);
			return;
		}
		const filteredData = data.flatMap((pageData: Node[]) => pageData?.filter((node) => node["name"].toLowerCase().includes(searchString.toLowerCase())));
		setCurrentData(filteredData);
		setTotalPages(1);
		setCurrentPage(1);
		setIsSearching(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
		setNormalView(true);
		setUnionView(false);
	};

	const openOnMap = (node) => {
		const currentGeo = geoRef.current;
		const nodesInPlay = [{ label: node.name, value: node.uuid }];
		const shapes = [currentGeo[`${node.uuid}`].properties.type === "circle" ? convertToCircle(currentGeo[`${node.uuid}`]) : currentGeo[`${node.uuid}`]];
		setTitle(currentGeo[`${node.uuid}`].properties.name);

		Object.keys(node.intersections.zone).forEach((key) => {
			shapes.push(currentGeo[`${key}`].properties.type === "circle" ? convertToCircle(currentGeo[`${key}`]) : currentGeo[`${key}`]);
			nodesInPlay.push({ value: currentGeo[`${key}`].properties.uuid, label: currentGeo[`${key}`].properties.name });
		});
		Object.keys(node.overlaps.zone).forEach((key) => {
			shapes.push(currentGeo[`${key}`].properties.type === "circle" ? convertToCircle(currentGeo[`${key}`]) : currentGeo[`${key}`]);
			nodesInPlay.push({ value: currentGeo[`${key}`].properties.uuid, label: currentGeo[`${key}`].properties.name });
		});
		Object.keys(node.intersections.location).forEach((key) => {
			shapes.push(currentGeo[`${key}`].properties.type === "circle" ? convertToCircle(currentGeo[`${key}`]) : currentGeo[`${key}`]);
			nodesInPlay.push({ value: currentGeo[`${key}`].properties.uuid, label: currentGeo[`${key}`].properties.name });
		});
		Object.keys(node.overlaps.location).forEach((key) => {
			shapes.push(currentGeo[`${key}`].properties.type === "circle" ? convertToCircle(currentGeo[`${key}`]) : currentGeo[`${key}`]);
			nodesInPlay.push({ value: currentGeo[`${key}`].properties.uuid, label: currentGeo[`${key}`].properties.name });
		});
		setShapes(shapes);
		setSelectedNodes(nodesInPlay);
		setSelectedShapes(shapes);
		setIsModalOpen(true);
	};

	const convertToCircle = (shape: Feature<Polygon | MultiPolygon>) => {
		const circleShape = turf.circle([shape.properties.lng, shape.properties.lat], shape.properties.radius, {
			units: "meters",
			properties: {
				...shape.properties
			}
		});

		// Ensure the coordinates are in the correct order for GeoJSON
		circleShape.geometry.coordinates = circleShape.geometry.coordinates.map(
			(coords: number[][]) => coords.map((coord: number[]) => [coord[1], coord[0]]) // Ensure [lng, lat] order
		);

		return circleShape;
	};

	const getIntersection = () => {
		if (selectedShapes.length === 0) {
			return;
		}
		let intersectedShape: Feature<Polygon | MultiPolygon> = selectedShapes[0].properties.type === "circle" ? convertToCircle(selectedShapes[0]) : selectedShapes[0];

		for (let i = 1; i < selectedShapes.length; i++) {
			const currentShape: Feature<Polygon | MultiPolygon> = selectedShapes[i].properties.type === "circle" ? convertToCircle(selectedShapes[i]) : shapes[i];
			intersectedShape = turf.intersect(turf.featureCollection([intersectedShape, currentShape]));

			// If at any point the intersection is null, break out
			if (!intersectedShape) return;
		}

		setIntersectedShapes([intersectedShape]);
		setNormalView(false);
		setUnionView(false);
	};

	const getUnion = () => {
		if (selectedShapes.length === 0) {
			return;
		}
		let combinedShape: Feature<Polygon | MultiPolygon> = selectedShapes[0].properties.type === "circle" ? convertToCircle(selectedShapes[0]) : selectedShapes[0];

		for (let i = 1; i < selectedShapes.length; i++) {
			const currentShape: Feature<Polygon | MultiPolygon> = selectedShapes[i].properties.type === "circle" ? convertToCircle(selectedShapes[i]) : selectedShapes[i];
			combinedShape = turf.union(turf.featureCollection([combinedShape, currentShape]));

			// If at any point the intersection is null, break out
			if (!combinedShape) return;
		}

		setCombinedShapes([combinedShape]);
		setNormalView(false);
		setUnionView(true);
	};

	const resetMap = () => {
		setNormalView(true);
		setUnionView(false);
	};

	useEffect(() => {
		geoRef.current = geo;
	}, [geo]);

	useEffect(() => {
		setLoading(true);
		fetchData();
		fetchShapes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSearching) return;
		setLoading(true);
		if (currentData.length) updateData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		if (normalView) return;
		if (unionView) {
			getUnion();
		} else {
			getIntersection();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedShapes]);

	return (
		<section id="node-table-container">
			<Typography variant="h6" id="node-info">
				Total Nodes: {totalNodes} | Total Groups: {originalData?.["totalPages"] || 0}
			</Typography>
			<Modal size="m" isOpen={isModalOpen} onClose={handleModalClose} trapFocus={false} className="modal-class">
				<InfoDetail detail={<Title className="paddingTop">{title}</Title>} />
				<DialogContent className="map-content">
					<SelectCheckboxesSearch
						id={"nodeIntel"}
						options={shapes.map((shape) => ({ label: shape.properties.name, value: shape.properties.uuid }))}
						placeholder={`Select conflicting nodes to view`}
						disabled={false}
						value={selectedNodes}
						onChange={(_event, newValue) => {
							setSelectedNodes(newValue);
							const updatedShapes = shapes.filter((shape) => newValue.some(({ value }) => shape.properties.uuid === value));
							setSelectedShapes(updatedShapes);
						}}
						name={"Select nodes"}
						elementRef={inputRef}
						className="select-nodes"
					/>
					<div style={{ position: "relative" }}>
						{normalView && <NodesMap shapes={selectedShapes} />}
						{!normalView && <NodesMap shapes={unionView ? combinedShapes : intersectedShapes} />}
						<Stack spacing={0} direction="row" className="show-on-map">
							<Button icon={<ShapeIntersect />} className="action-button" size="small" variant="no-bg" onClick={getIntersection} label={"Intersection"} />
							<Button icon={<ShapeUnite />} className="action-button" size="small" variant="no-bg" onClick={getUnion} label={"Union"} />
							<Button icon={<Reset />} className="action-button" size="small" variant="no-bg" onClick={resetMap} label={"Reset"} />
						</Stack>
					</div>
					<div className="legend">
						<div className="legend-item">
							<span className="legend-color blue" />
							<span>Zone</span>
						</div>
						<div className="legend-item">
							<span className="legend-color yellow" />
							<span>Location</span>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button label="Close" variant="contained" size="small" onClick={handleModalClose} />
				</DialogActions>
			</Modal>
			{!loading && (
				<div className="group-table">
					<Table showFilters={false} isSelectable={false} columns={columns} visibleColumns={columns} data={currentData} total={totalNodes} page={currentPage} pageSize={currentData?.length} pagination={false} sticky={true} className={"node-table"} handleEvent={handleSearch} />
					<Pagination className="node-table-pagination" total={totalPages} current={currentPage} onChangePage={(value: number) => setCurrentPage(value)} />
				</div>
			)}
			{loading && <Skeleton className="skeleton"></Skeleton>}
		</section>
	);
}

export default NodesTable;
