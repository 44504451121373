import { MapContainer, TileLayer, Marker, Circle, Polygon as LeafletPolygon, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { LatLngExpression, latLngBounds, DivIcon } from "leaflet";
import { Feature } from "geojson";
import { COLORS } from "../NodeIntelConfig";

interface NodesMapProps {
	shapes: Feature[];
}

const colors = { zone: COLORS.MAP_BLUE, location: COLORS.MAP_YELLOW };

const RenderShapes: React.FC<{ shapes: Feature[] }> = ({ shapes }) => {
	return (
		<>
			{shapes.map((shape, index) => {
				const color = colors[shape?.properties?.visibility] || "red";

				if (shape?.properties?.type === "circle") {
					return (
						<>
							<Circle key={index} center={{ lat: shape?.properties?.lat, lng: shape?.properties?.lng }} radius={shape?.properties?.radius} color={color} weight={1} />
							{shape.properties.name && (
								<Marker
									position={{ lat: shape?.properties?.lat, lng: shape?.properties?.lng }}
									icon={
										new DivIcon({
											className: "label",
											html: `<div style="background-color: rgba(255, 255, 255, 0.7); padding: 5px; border-radius: 5px; display: inline-block;">${shape.properties.name}</div>`
										})
									}
								/>
							)}
						</>
					);
				} else if (shape?.geometry?.type === "Polygon") {
					const positions: LatLngExpression[] = shape.geometry.coordinates[0].map((coords) => [coords[0], coords[1]]);
					return (
						<>
							<LeafletPolygon key={index} positions={positions} color={color} weight={1} />
							{shape.properties.name && (
								<Marker
									position={positions[0]}
									icon={
										new DivIcon({
											className: "label",
											html: `<div style="background-color: rgba(255, 255, 255, 0.7); padding: 5px; border-radius: 5px; display: inline-block;">${shape.properties.name}</div>`
										})
									}
								/>
							)}
						</>
					);
				} else if (shape?.geometry?.type === "MultiPolygon") {
					return shape.geometry.coordinates.map((polygon, polygonIndex) => {
						const positions: LatLngExpression[] = polygon[0].map((coords) => [coords[0], coords[1]]);
						return (
							<>
								<LeafletPolygon key={`${index}-${polygonIndex}`} positions={positions} color={color} weight={1} />
								{shape.properties.name && (
									<Marker
										position={positions[0]}
										icon={
											new DivIcon({
												className: "label",
												html: `<div style="background-color: rgba(255, 255, 255, 0.7); padding: 5px; border-radius: 5px; display: inline-block;">${shape.properties.name}</div>`
											})
										}
									/>
								)}
							</>
						);
					});
				}
				return null;
			})}
		</>
	);
};

const NodesMap: React.FC<NodesMapProps> = ({ shapes }) => {
	const getMapCenter = (): LatLngExpression => {
		if (!shapes?.length) return;

		const bounds: LatLngExpression[] = shapes.map((shape) => shape.geometry?.["coordinates"][0]);
		const boundsInstance = latLngBounds(bounds);
		const center: LatLngExpression = boundsInstance.getCenter();

		return [center.lat, center.lng];
	};

	const getMapZoom = (): number => {
		if (!shapes?.length) return;

		const bounds: LatLngExpression[] = shapes.map((shape) => shape?.geometry?.["coordinates"]?.[0]);
		const boundsInstance = latLngBounds(bounds);
		const southWest = boundsInstance.getSouthWest();
		const northEast = boundsInstance.getNorthEast();
		const latDiff = northEast.lat - southWest.lat;
		const lngDiff = northEast.lng - southWest.lng;
		// assuming map size to be 600 x 400 for approx center calculations
		const latZoom = Math.log2(400 / ((latDiff * 360) / 256));
		const lngZoom = Math.log2(600 / ((lngDiff * 360) / 256));

		return Math.floor(Math.min(latZoom, lngZoom));
	};

	return (
		<MapContainer center={getMapCenter()} zoom={getMapZoom()} className="map-container">
			<TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} />
			<RenderShapes shapes={shapes} />
		</MapContainer>
	);
};

export default NodesMap;
