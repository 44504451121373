export const ENDPOINTS = {
	NODE_GEO: "/account/node-geo/",
	NODE_INFO: "/account/node-intel/",
	SWITCH_ACCOUNTS: "/admin/delegate",
	ACCOUNTS_QUICKVIEW: "/accounts?quick_view=true&all=true",
	LOGOUT: "/auth/logout",
	AUTH: "/auth"
};
export const COLORS = {
	MAP_BLUE: "#094652",
	MAP_YELLOW: "#664400",
	TAG_BLUE: "#DEF3F8",
	TAG_YELLOW: "#FFF8EB"
};
